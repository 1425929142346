export const environment = {
  production: false,
  serverUrl: 'https://dev.skpt.com',
  apiPrefix: '/api',

  ssoEmailDomains: ['skpt.com', 'cfbm.com', 'skbiotek.ie', 'apfc.com', 'yposkesi.com'],
 
  // SKPT Okta configs
  oktaConfig: {
    clientId: '0oal50m7t7EK8U9zH5d7',
    issuer: 'https://cfbm.okta.com',
    redirectUri: 'https://dev.skpt.com/login/callback',
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    refreshTokenUrl: 'https://cfbm.okta.com/oauth2/v1/token'
  }
};
